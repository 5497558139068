import { isOldSafari } from '@utils/isOldSafari'

const assets = {
    gender_step: `https://images.ctfassets.net/i6d954lr9wft/7M9axV3NtMMkhbyoIfSXl6/413b21cb36cec937e5d977b114f5f709/storytelling_05.svg?h=80`,
    trust_story_tabitha: {
        metric: `https://images.ctfassets.net/i6d954lr9wft/4YbQc2kGHwVSdP6UvNps9C/290a4c01155908fd024a327f1b5b0c10/tabitha-kg.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        imperial: `https://images.ctfassets.net/i6d954lr9wft/4mvllOKT2vQV9ZfgiApNV4/09ecb6158f7018618186eeca2b7dd3ee/tabitha-lb.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    trust_story_henrica: {
        metric: `https://images.ctfassets.net/i6d954lr9wft/1ymusyxjvuUEecC1M4MK6K/6c940e28efc7d4cd3c8a30bf5c2ffd48/henrica-kg.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        imperial: `https://images.ctfassets.net/i6d954lr9wft/35aOeJF6cDwqs9bC70gH0R/ad425fe848644e8633ef3bc7021a61ab/henrica-lb.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    trust_story_hilger: {
        metric: `https://images.ctfassets.net/i6d954lr9wft/2iSzZRiEXYJhkAlQ42chxq/3424827c0a7bd57a8fe97e5fc941f5a0/hilger-kg.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        imperial: `https://images.ctfassets.net/i6d954lr9wft/43pZDrbOlEsizRRcmf876m/66bada072362600124f0244b7de2f3e5/hilger-lb.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    trust_story_johannes: {
        metric: `https://images.ctfassets.net/i6d954lr9wft/6k2BlnxBvupyeD47uDX00x/e85ee15aa904bb988522e65d285ec9fc/johannes-kg.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        imperial: `https://images.ctfassets.net/i6d954lr9wft/1kQLcdrls22uGctlxjZudB/eb7352ee46b4cb2f46a99f8f38c52544/johannes-lb.jpg?h=280${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    developed_by: {
        conny_wettcke: `https://images.ctfassets.net/i6d954lr9wft/59g0f1wz8HioS95Jj3drxY/ec80499c260d716b7c6fa8fb6e9c8f3b/Conny_Wettcke_web.jpg?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        alice_fontecilla: `https://images.ctfassets.net/i6d954lr9wft/3yAqE5DXDQc9fEPkKVYbNM/9a92708bc8605fe38f31780c9d925c50/Alice_FontecillaFEEDBACKLOOP.jpg?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        mario_ost: `https://images.ctfassets.net/i6d954lr9wft/C1hGgm4Qq4FBPkJOdzjFZ/0892f822e66d3e5c0fb05160846b9c14/MarioOst_whiteBG.png?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        ilona_bürgel: `https://images.ctfassets.net/i6d954lr9wft/2PGKngDovPjqD3JLffkUVC/121eda7d8aacdce0754724a3b6829128/IlonaBu__rgel_whiteBG.png?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        sarah_wander: `https://images.ctfassets.net/i6d954lr9wft/7ssRZfHc2BwbB1c7RQmVH5/507dd57be8a3ad33d1fee9bd9f1be09c/SarahWander_whiteBG.png?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        reiner_wettcke: `https://images.ctfassets.net/i6d954lr9wft/2AGZJ8igs7WxCSzoEW4deu/236ebc02fe54ffa5308e693138699293/ReinerWettcke_whiteBG.png?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
        janet_brill: `https://images.ctfassets.net/i6d954lr9wft/5iuhZHjZ1Ijn5qPviarCtI/131a6d925e8d693209dd3d2336f31b63/JanetBrill_whiteBG.png?h=112${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    body_woman_rounded:
        'https://images.ctfassets.net/eejvltlfzbsz/1H20eyOZqz7nfvSG25nhTW/8ac772168856c86f5956259419fb6001/woman_2.svg',
    body_woman_triangle:
        'https://images.ctfassets.net/eejvltlfzbsz/H1NZkcvnKu6keJmomDswi/bae14f19c1bbbf577d585b44c1b5d9fb/woman_5.svg',
    body_woman_rectangle:
        'https://images.ctfassets.net/eejvltlfzbsz/2Z6VcONl4HIJlh1LjZ3BIB/01329cbab9b7b6c3abfdb85e56decec9/woman_4.svg',
    body_woman_inverted_triangle:
        'https://images.ctfassets.net/eejvltlfzbsz/6TQAQsTyotJ3GrorXFwdcX/29c06dce2a48642d6db2612bae7b9919/woman_3.svg',
    body_woman_hourglass:
        'https://images.ctfassets.net/eejvltlfzbsz/4Z8a0VUEismPmd6e6k7qeZ/a2fd1519f7316e7981f4287368fb71fd/woman_1.svg',
    body_man_rounded:
        'https://images.ctfassets.net/eejvltlfzbsz/1SwxuyxXnZjjtslOfM1vp1/b565365664e49cd6e3b85fcd52cabb12/man_2.svg',
    body_man_triangle:
        'https://images.ctfassets.net/eejvltlfzbsz/mYLaffG2NEqkBQiS9Hxyd/93459dde350ea6137765ca7b85cd2da3/man_5.svg',
    body_man_rectangle:
        'https://images.ctfassets.net/eejvltlfzbsz/6D8aY9RaN2yHigpDqhhHmK/4c48a923893d04907cee575d0d7250fa/man_4.svg',
    body_man_inverted_triangle:
        'https://images.ctfassets.net/eejvltlfzbsz/4J2kvSyUUskSfOGBIvF4jz/77178bc407964dc9228fef38e9fb6dfe/man_3.svg',
    body_man_hourglass:
        'https://images.ctfassets.net/eejvltlfzbsz/3Og1rNdp9yM8U5NbILHKCr/29240bf23f2735713034129cb89d5e53/man_1.svg',
    trust_national_center: `https://images.ctfassets.net/eejvltlfzbsz/7rdJAZkzjORgFHTu2vEsch/e5230e3981cf4c8e96db3d509484f3de/national_center_biotechnology.png?w=200${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    trust_rochester_medical: `https://images.ctfassets.net/eejvltlfzbsz/7ffEKQSp3yWu2AzBi6ugtk/49e1dfb7b302b9066d15055b179f8242/university_of_rochester.png?w=200${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    story_1:
        'https://images.ctfassets.net/i6d954lr9wft/38A8WZVpO7kSrNvv2CO98c/1bf896746d558abbcc5ccfccd88e07e1/storytelling_1.svg',
    story_2:
        'https://images.ctfassets.net/i6d954lr9wft/38EYoZOgrWs7kEvp6zcgQa/d5fb292554a47924d38885562b6e423e/storytelling_2.svg',
    story_3:
        'https://images.ctfassets.net/i6d954lr9wft/4EYfShTGYuYAhs50sbZM2j/32d9f9cbf00b45b4067e1530ff699b47/storytelling_3.svg',
    story_4:
        'https://images.ctfassets.net/i6d954lr9wft/56YVQHhtgj2TgsiRe7nROt/55276303022b41c72d71889dec52d8a3/storytelling_4.svg',
    story_5:
        'https://images.ctfassets.net/i6d954lr9wft/4uIB69m7P1vYJr9hqhSzeg/5ba5fb935d29d54e3bc51326d1581f4e/storytelling_5.svg',
    story_6:
        'https://images.ctfassets.net/i6d954lr9wft/41RQbHwUvctN4qTtvbJUJJ/3f259148e6982abaefbd2f5ddeb2c895/storytelling_6.svg',
    story_7:
        'https://images.ctfassets.net/i6d954lr9wft/3wvo4ciGz5mVVQ6B1OplVx/08661a09e9b05a8a8df5dc38dee4712a/storytelling_7.svg',
    story_8:
        'https://images.ctfassets.net/i6d954lr9wft/PQ22K3d8gHXacI6VAl1d5/09e8129bede8228a0fc50685b29e2d91/storytelling_8.svg',
    story_9:
        'https://images.ctfassets.net/i6d954lr9wft/3QMTqTgDmQ4WoweXyx68gY/ae5c2ed51262fc7daee6aa5285622d9a/storytelling_9.svg',
    story_10:
        'https://images.ctfassets.net/i6d954lr9wft/iGdJtwMRN4yhRQq4yHtSI/9af92ed2cae56755fef946993d9f2d19/storytelling_10.svg',
    patricia_jones: `https://images.ctfassets.net/eejvltlfzbsz/79VNdsqloWdpbgvKRirJW4/e575726fe2f462e8d92cc23e1bf2d865/Patricia_Jones.jpg.png?w=64${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    maximilian_stritt: `https://images.ctfassets.net/eejvltlfzbsz/ue9osGcgvIjXzEcBS0Q3s/b7068d97f6b2e47ba79b007348b551b8/Maximilian_Stritt.png?w=64${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    virginia_smith: `https://images.ctfassets.net/eejvltlfzbsz/3Cxt2ZWBkZZYSi0Az2HG5R/ccad1ab3ced5ab793c844fa568644654/Virginia_Smith.jpg.png?w=64${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    edward_miller: `https://images.ctfassets.net/eejvltlfzbsz/4q6rbuQzRk32KSi9vQJ2cq/74f1ca93b3d94edaf4669444eb87de9d/Edward_Miller.jpg.png?w=64${
        !isOldSafari() ? '&fm=webp' : ''
    }`,
    fastic_app_mockup: {
        mobile: `https://images.ctfassets.net/i6d954lr9wft/3Iwv3COj2M56WhuU9AaeuJ/fc395e9bcc5f1d38e33b007099f7e93a/iphone_fastic_app_mockup.png${
            !isOldSafari() && '?fm=webp'
        }`,
        web: `https://images.ctfassets.net/i6d954lr9wft/2kghRdn1GajqE8I5vhhT8p/c05852df99000913883b1b65d70fd096/fastic_app_mockup_web.png${
            !isOldSafari() && '?fm=webp'
        }`,
    },
    trial_breakdown:
        'https://images.ctfassets.net/i6d954lr9wft/4yZlrCtRjbRSS2JziaWFJP/eb46afd63782c6c321f6dbb3497e813b/Wisdom.svg',
    fastic_download: {
        android: `https://images.ctfassets.net/i6d954lr9wft/3ccouGpvj4YuQboLVwgrvf/8bf911208f5aacf77a5b183bca824fc8/fastic-appstore_android.png${
            !isOldSafari() && '?fm=webp'
        }`,
        ios: `https://images.ctfassets.net/i6d954lr9wft/3DgfZ2QXfuy8brbzraJQFy/22133bf2949beb88c751f363bce18059/fastic-appstore-ios.png${
            !isOldSafari() && '?fm=webp'
        }`,
        web: `https://images.ctfassets.net/i6d954lr9wft/45MRFZd8QIiYDXXbJfNqXH/8b56d8cb3ba30aca962933f75d5f6eb3/fastic-appstore-web.png${
            !isOldSafari() && '?fm=webp'
        }`,
    },
    your_plan: {
        eat:
            'https://images.ctfassets.net/i6d954lr9wft/52HoKLnDHZeLvbzbiOQeeH/e37b611445747cabd7b2bb8551e6eab6/eat.svg',
        sleep:
            'https://images.ctfassets.net/i6d954lr9wft/XnzxegloFKYBiChlIz6ss/d629d18e9afc328008d31b0817f0fcd9/sleep.svg',
        community:
            'https://images.ctfassets.net/i6d954lr9wft/3nTDna25kmisrdZ0lknoFD/170d825a67075522353707c5e60e51b6/community.svg',
        exercise:
            'https://images.ctfassets.net/i6d954lr9wft/1qp6GzMHVcs7JMNad946MF/2a9a3b769048fca1c2940b370fb110b7/exercise.svg',
    },
    landing: {
        variantA: {
            backgroundLarge:
                'https://images.ctfassets.net/i6d954lr9wft/4OMpworYLcwnhkZ2JlXWee/8fa53c09a76426e408074532d808d8a2/bg-large.png',
            backgroundSmall:
                'https://images.ctfassets.net/i6d954lr9wft/4296dooeSisVX5dzDe1Fdg/68d79ea321c33fd1141c65568e22e547/bg-small.png',
        },
        variantB: {
            human_with_fasting:
                'https://images.ctfassets.net/i6d954lr9wft/3sJbWCJk0y8AKyS8OoCG6S/4bade75851a0475a86112a1af647ca9c/Group_10353.png',
            human_without_fasting:
                'https://images.ctfassets.net/i6d954lr9wft/4XTHKrTDCDcU23zMDeKAGi/017770293ee0d5dcebc469e63b5eee26/Group_10352.png',
            backgroundSmall:
                'https://images.ctfassets.net/i6d954lr9wft/4296dooeSisVX5dzDe1Fdg/68d79ea321c33fd1141c65568e22e547/bg-small.png',
        },
    },
    sign_in: {
        mocks:
            'https://images.ctfassets.net/i6d954lr9wft/6VohpFAjZBfukrLAYrLGuU/45257a4e78a5a23fe32df777dd175333/sign-in-mocks.png',
    },
}

export default assets
