import { EmailValidator } from 'commons-validator-js'

export const validateHeight = (cm: number): string | null => {
    return cm && (cm < 91 || cm > 214) ? 'common:errors.height' : null
}

export const validateWeight = (kg: number): string | null => {
    if (kg && kg < 36) {
        return 'common:errors.current_weight.min'
    }
    return kg && kg > 226 ? 'common:errors.current_weight.max' : null
}

export const validateTargetWeight = (targetKg: number, currentKg: number): string | null => {
    if (targetKg && (targetKg < 50 || targetKg / currentKg < 0.5)) {
        return 'common:errors.target_weight.min'
    }
    return targetKg && targetKg > currentKg ? 'common:errors.target_weight.max' : null
}

const emailValidator = new EmailValidator()

export const validateEmail = (email: string): string | null => {
    if (emailValidator.isValid(email)) {
        return null
    }
    return 'common:errors.email.invalid'
}

export const validatePassword = (password: string): string | null => {
    if (password && password.trim().length >= 8) {
        return null
    }
    return 'common:errors.password.min'
}

export const validateName = (name: string): string | null => {
    if (name && name.trim().length >= 2) {
        return null
    }
    return 'common:errors.name.min'
}
